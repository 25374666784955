import { color } from "../../../theme/color";

interface StyleSheet {
  [key: string]: React.CSSProperties;
}

const pointerView = {
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: color.background,
  height: 100,
};

const DROPPED_ROW_VIEW = {
  borderWidth: 1,
  borderStyle: "dashed",
  borderRadius: 1,
  borderColor: color.background,
  height: 100,
};
const styles: StyleSheet = {
  rowIcon: {
    color: color.background,
    fontSize: 25,
  },
  rowLabel: {
    color: color.lightWhite,
    fontSize: 14,
    marginTop: 5,
    textAlign: "center",
    alignSelf: "center",
  },
  rowView: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: color.background,
    height: 100,
  },
  enterPointer: {
    ...pointerView,
    backgroundColor: color.mousePointer,
  },
  leavePointer: {
    ...pointerView,
    backgroundColor: color.background,
  },
  droppedRowView: {
    ...DROPPED_ROW_VIEW,
  },
  droppedRowContentView: {
    ...DROPPED_ROW_VIEW,
    height: 80,
  },
  selectedDroppedRowView: {
    ...DROPPED_ROW_VIEW,
    borderStyle: "solid",
    borderColor: "blue",
  },
  dropView: {
    marginLeft: 20,
    marginRight: 20,
    cursor: "pointer",
  },
  droppedRowContent: {
    marginLeft: 40,
    marginRight: 40,
    borderWidth: 1,
    borderStyle: "dashed",
    borderRadius: 1,
    borderColor: color.background,
    height: 100,
  },
  rowIconView: {
    height: 15,
    width: 80,
    borderColor: color.white,
    borderWidth: 1,
    borderStyle: "dashed",
    marginTop: 5,
  },
  columnIconView: {
    height: 40,
    width: 15,
    borderColor: color.white,
    borderWidth: 1,
    borderStyle: "dashed",
    marginLeft: 5,
  },
  gridIconView: {
    height: 20,
    width: 20,
    borderColor: color.white,
    borderWidth: 1,
    borderStyle: "dashed",
    marginLeft: 5,
    marginTop: 5,
  },
  rootView: {
    overflowY: "scroll",
    flex: 1,
    backgroundColor: "white",
    paddingBottom: 15,
    paddingTop: 15,
  },
  componentBorder: {
    flex: 1,
    borderWidth: 1,
    borderColor: color.lightWhite,
    borderStyle: "solid",
    marginRight: 15,
    marginLeft: 15,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 5,
  },
  componentText: {
    alignSelf: "center",
    color: color.white,
    fontWeight: "bold",
    textAlign: "center",
    cursor: "pointer",
  },
};

export default styles;
