import React from "react";
import { UICoreRow } from "../../../uikit/ui-core-row/ui-core.row";
import {
  BorderInnerOutlined,
  CaretRightOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CloseOutlined,
  RotateLeftOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { UICoreCol } from "../../../uikit/ui-core-col/ui-core.col";
import { Collapse, Select } from "antd";
import { color } from "../../../theme/color";
import UICoreTypography from "../../../uikit/ui-core-typography/ui-core.typography";
import styles from "./right-panel.style";
import UICoreInput from "../../../uikit/ui-core-input/ui-core.input";
import "./index.css";
import UICoreSelect from "../../../uikit/ui-core-select/ui-core.select";
import { getFittingListData } from "../../../utils/utils";
import "./index.css";

const { Option } = Select;

const { Panel } = Collapse;

export default function BackgroundView() {
  function renderHeader(label: any): any {
    return <UICoreTypography style={styles.collapseLabel} title={label} />;
  }

  return (
    <Collapse
      bordered={false}
      className={"header"}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ color: color.white }}
          rotate={isActive ? 90 : 0}
        />
      )}
    >
      <Panel
        style={{ backgroundColor: color.background, borderColor: color.black }}
        header={renderHeader("Background")}
        key="1"
      >
        <UICoreCol style={{ width: "100%" }}>
          <UICoreRow style={{ marginTop: 10 }}>
            <UICoreTypography
              style={styles.labelFlex1}
              title={"Image & gradient"}
            />
            <PlusOutlined style={{ color: color.white }} />
          </UICoreRow>

          <UICoreCol
            style={Object.assign(styles.layoutLine, { marginTop: 15 })}
          />

          <UICoreRow style={{ marginTop: 10 }}>
            <UICoreRow style={{ flex: 1 }}>
              <UICoreTypography
                style={styles.layoutDisplayLabel}
                title={"Color"}
              />
              <UICoreInput
                placeholder={"#333"}
                maxLength={5}
                style={styles.sizeInputColor}
              />
            </UICoreRow>
          </UICoreRow>

          <UICoreRow style={{ marginTop: 10 }}>
            <UICoreTypography
              style={styles.layoutDisplayLabel}
              title={"Clipping"}
            />
            <UICoreRow style={{ flex: 1 }}>
              <UICoreSelect
                defaultValue={"None"}
                dataList={getFittingListData()}
              />
            </UICoreRow>
          </UICoreRow>
        </UICoreCol>
      </Panel>
    </Collapse>
  );
}
