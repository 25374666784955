import React from "react";
import { MiddlePanelProps, BottomItemType } from "./middle-panel.props";
import { UICoreCol } from "../../../uikit/ui-core-col/ui-core.col";
import styles from "./middle-panel.style";
import UICoreTypography from "../../../uikit/ui-core-typography/ui-core.typography";
import { UICoreRow } from "../../../uikit/ui-core-row/ui-core.row";
import { useMiddlePanel } from "./middle-panel.hooks";
import { color } from "../../../theme/color";

export default function BottomItemView(props: MiddlePanelProps) {
  const { bottomItemType, onDragStart, onDragOver } = props;
  const {
    rowBackground,
    setRowBackground,
    columnBackground,
    setColumnBackground,
    gridBackground,
    setGridBackground,
  } = useMiddlePanel();
  let returnView = null;

  const onMouseEnter = (type: BottomItemType) => {
    if (type === "row") {
      setRowBackground(true);
    } else if (type === "column") {
      setColumnBackground(true);
    } else if (type === "grid") {
      setGridBackground(true);
    }
  };

  const onMouseLeave = (type: BottomItemType) => {
    if (type === "row") {
      setRowBackground(false);
    } else if (type === "column") {
      setColumnBackground(false);
    } else if (type === "grid") {
      setGridBackground(false);
    }
  };

  if (bottomItemType === "row") {
    returnView = (
      <UICoreCol
        draggable
        onDragOver={onDragOver}
        onMouseEnter={() => {
          onMouseEnter("row");
        }}
        onMouseLeave={() => {
          onMouseLeave("row");
        }}
        onDragStart={onDragStart}
        style={rowBackground ? styles.enterPointer : styles.leavePointer}
      >
        <UICoreCol>
          <UICoreCol style={styles.rowIconView} />
          <UICoreCol style={styles.rowIconView} />
          <UICoreTypography style={styles.rowLabel} title={"Row"} />
        </UICoreCol>
      </UICoreCol>
    );
  } else if (bottomItemType === "column") {
    returnView = (
      <UICoreCol
        draggable
        onMouseEnter={() => {
          onMouseEnter("column");
        }}
        onMouseLeave={() => {
          onMouseLeave("column");
        }}
        onDragStart={onDragStart}
        style={columnBackground ? styles.enterPointer : styles.leavePointer}
      >
        <UICoreCol>
          <UICoreRow>
            <UICoreCol style={styles.columnIconView} />
            <UICoreCol style={styles.columnIconView} />
          </UICoreRow>
          <UICoreTypography style={styles.rowLabel} title={"Column"} />
        </UICoreCol>
      </UICoreCol>
    );
  } else if (bottomItemType === "grid") {
    returnView = (
      <UICoreCol
        draggable
        onMouseEnter={() => {
          onMouseEnter("grid");
        }}
        onMouseLeave={() => {
          onMouseLeave("grid");
        }}
        onDragStart={onDragStart}
        style={gridBackground ? styles.enterPointer : styles.leavePointer}
      >
        <UICoreCol>
          <UICoreRow>
            <UICoreCol style={styles.gridIconView} />
            <UICoreCol style={styles.gridIconView} />
          </UICoreRow>
          <UICoreRow>
            <UICoreCol style={styles.gridIconView} />
            <UICoreCol style={styles.gridIconView} />
          </UICoreRow>
          <UICoreTypography style={styles.rowLabel} title={"Grid"} />
        </UICoreCol>
      </UICoreCol>
    );
  }
  return returnView;
}
