import React from "react";
import { Layout } from "antd";
import { UICoreScreenProps } from "./components/ui-core-screen.props";
import styles from "./components/ui-core-screen.style";

function UIScreen(props: UICoreScreenProps) {
  const { style, children } = props;
  return <Layout style={{ ...styles.container, ...style }}>{children}</Layout>;
}

export function UICoreScreen(props: UICoreScreenProps) {
  return <UIScreen {...props} />;
}
