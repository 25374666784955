import React, { useEffect } from "react";
import { LeftPanelProps } from "./left-panel.props";
import styles from "./left-panel.style";
import UICoreTypography from "../../../uikit/ui-core-typography/ui-core.typography";
import { UICoreScreen } from "../../ui-core-screen/ui-core.screen";
import { PlusCircleFilled } from "@ant-design/icons";
import { UICoreRow } from "../../../uikit/ui-core-row/ui-core.row";
import { useLeftPanel } from "./left-panel.hooks";
import { log } from "../../../utils/utils";
import UICoreList from "../../../uikit/ui-core-list/ui-core.list";

let screenNumber = 0;

function LeftPanelView(props: LeftPanelProps) {
  const {
    screens,
    setScreen,
    selectedIndex,
    setSelectedIndex,
  } = useLeftPanel();

  useEffect(() => {
    screenNumber = 0;
  }, []);

  const addNewScreen = () => {
    screenNumber = screenNumber + 1;
    let arrayList = screens.slice();
    arrayList.push({
      name: "Screen " + screenNumber,
    });
    setScreen(arrayList);
    log(arrayList);
  };

  const selectScreen = (index: any) => {
    setSelectedIndex(index);
  };
  return (
    <UICoreScreen style={styles.root}>
      <UICoreRow style={styles.addScreenView}>
        <UICoreTypography style={styles.addScreenLabel} title={"Add Screen"} />
        <PlusCircleFilled
          onClick={() => {
            addNewScreen();
          }}
          style={styles.addScreenIcon}
        />
      </UICoreRow>

      {screens.length > 0 && (
        <UICoreList
          data={screens}
          renderItem={(item: any, index: any) => (
            <UICoreRow
              onClick={() => {
                selectScreen(index);
              }}
              style={
                index === selectedIndex ? styles.selectedItem : styles.listItem
              }
            >
              <UICoreTypography
                style={styles.listItemLabel}
                title={item.name}
              />
            </UICoreRow>
          )}
        />
      )}
    </UICoreScreen>
  );
}

export default LeftPanelView;
