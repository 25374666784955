import React from "react";
import { Typography } from "antd";
import { UICoreTypographyProps } from "./components/ui-core-typography.props";
import "./index.css";

function UICoreTypography(props: UICoreTypographyProps) {
  const { style, title, ...rest } = props;
  return (
    <Typography className={"disable-select"} {...rest} style={style}>
      {title}
    </Typography>
  );
}

export default UICoreTypography;
