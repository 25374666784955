import { palette } from "./palette";

export const color = {
  palette,
  transparent: palette.transparent,
  background: palette.background,
  white: palette.white,
  lightWhite: palette.lightWhite,
  lightBlack: palette.lightBlack,
  black: palette.black,
  disable: palette.disable,
  itemBackground: palette.itemBackground,
  mousePointer: palette.mousePointer,
};
