import React from "react";
import { List } from "antd";
import { UICoreListProps } from "./components/ui-core-list.props";

function UICoreList(props: UICoreListProps) {
  const { style, data, renderItem } = props;
  return <List style={style} dataSource={data} renderItem={renderItem} />;
}

export default UICoreList;
