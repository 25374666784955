import { color } from "../../../theme/color";

interface StyleSheet {
  [key: string]: React.CSSProperties;
}
const styles: StyleSheet = {
  root: {
    flex: 0.3,
    backgroundColor: color.background,
  },
  treeView: {
    backgroundColor: color.background,
    color: color.lightWhite,
    fontSize: 14,
    fontWeight: "bold",
  },
  layoutIcon: {
    backgroundColor: color.disable,
    padding: 5,
    borderRadius: 1,
    marginRight: 1,
    fontSize: 12,
    cursor: "pointer",
    color: color.white,
    flex: 1,
  },
  autoIcon: {
    backgroundColor: color.disable,
    padding: 2.5,
    borderRadius: 1,
    cursor: "pointer",
    fontSize: 11,
    color: color.white,
  },
  layoutLastIcon: {
    backgroundColor: color.lightBlack,
    padding: 5,
    borderRadius: 1,
    fontSize: 12,
    cursor: "pointer",
    flex: 1,
    color: color.white,
  },
  layoutIconView: {
    width: "100%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: color.black,
  },
  layoutDisplayLabel: {
    color: color.lightWhite,
    fontSize: 12,
    fontWeight: "normal",
    marginRight: 5,
    flex: 0.4,
  },
  selectedDirectionView: {
    height: 25,
    backgroundColor: color.lightBlack,
    borderRadius: 1,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    flex: 1,
  },
  unselectedDirectionView: {
    height: 25,
    borderRadius: 1,
    backgroundColor: color.disable,
    cursor: "pointer",
    flex: 1,
  },
  selectedDirectionLabel: {
    color: color.lightWhite,
    fontSize: 12,
    textAlign: "center",
    fontWeight: "normal",
    alignSelf: "center",
    marginTop: 3,
  },
  unselectedDirectionLabel: {
    color: color.lightWhite,
    fontSize: 12,
    fontWeight: "normal",
    textAlign: "center",
    alignSelf: "center",
    marginTop: 3,
  },
  collapseLabel: {
    fontSize: 13,
    fontWeight: "bold",
    color: color.lightWhite,
  },
  collapseItemLabel: {
    fontSize: 12,
    color: color.lightWhite,
  },
  layoutLine: {
    height: 1,
    width: "100%",
    background: color.lightBlack,
    marginTop: 8,
    marginBottom: 8,
  },
  sizeInputLabel: {
    color: color.lightWhite,
    fontSize: 12,
    fontWeight: "normal",
    flex: 1,
  },
  sizeInput: {
    backgroundColor: color.lightBlack,
    color: color.white,
    fontSize: 12,
    height: 25,
    marginLeft: 10,
    borderColor: color.black,
    flex: 0.7,
  },

  sizeInputColor: {
    backgroundColor: color.lightBlack,
    color: color.white,
    fontSize: 12,
    width: 50,
    height: 25,
    marginLeft: 10,
    borderColor: color.black,
    flex: 1,
  },
  labelFlex1: {
    color: color.lightWhite,
    fontSize: 12,
    fontWeight: "normal",
    flex: 1,
  },
  boarderLabelFlex1: {
    color: color.lightWhite,
    fontSize: 12,
    fontWeight: "normal",
    flex: 1,
    marginRight: 10,
  },
  borderInput: {
    backgroundColor: color.lightBlack,
    color: color.white,
    fontSize: 12,
    width: 50,
    height: 25,
    marginLeft: 5,
    borderColor: color.black,
    flex: 0.6,
  },
};

export default styles;
