import React from "react";

export function useMiddlePanel() {
  const initArray: any[] = [];
  const [viewList, setView] = React.useState(initArray);
  const [rowContents, setRowContent] = React.useState(initArray);
  const [rowBackground, setRowBackground] = React.useState(false);
  const [columnBackground, setColumnBackground] = React.useState(false);
  const [gridBackground, setGridBackground] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  return {
    viewList,
    setView,
    rowBackground,
    setRowBackground,
    columnBackground,
    setColumnBackground,
    gridBackground,
    setGridBackground,
    rowContents,
    setRowContent,
    selectedIndex,
    setSelectedIndex,
  };
}
