import React from "react";

export function useLeftPanel() {
  const initArray: any[] = [];
  const [screens, setScreen] = React.useState(initArray);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  return {
    screens,
    setScreen,
    selectedIndex,
    setSelectedIndex,
  };
}
