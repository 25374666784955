import React from "react";
import { UICoreRow } from "../../../uikit/ui-core-row/ui-core.row";
import {
  CaretRightOutlined,
  CheckSquareOutlined,
  BorderOutlined,
  BorderOuterOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { UICoreCol } from "../../../uikit/ui-core-col/ui-core.col";
import { Collapse, Select } from "antd";
import { color } from "../../../theme/color";
import UICoreTypography from "../../../uikit/ui-core-typography/ui-core.typography";
import styles from "./right-panel.style";
import UICoreInput from "../../../uikit/ui-core-input/ui-core.input";
import "./index.css";
import "./index.css";
import UICoreSlider from "../../../uikit/ui-core-slider/ui-core-slider";
import UICoreSelect from "../../../uikit/ui-core-select/ui-core.select";
import { getFittingListData } from "../../../utils/utils";

const { Panel } = Collapse;

export default function EffectsView() {
  function renderHeader(label: any): any {
    return <UICoreTypography style={styles.collapseLabel} title={label} />;
  }

  function getAddItem(title: any) {
    return (
      <UICoreCol>
        <UICoreRow style={{ marginTop: 8 }}>
          <UICoreTypography style={styles.labelFlex1} title={title} />
          <PlusOutlined style={{ color: color.white }} />
        </UICoreRow>
        <UICoreCol style={styles.layoutLine} />
      </UICoreCol>
    );
  }

  return (
    <Collapse
      bordered={false}
      className={"header"}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ color: color.white }}
          rotate={isActive ? 90 : 0}
        />
      )}
    >
      <Panel
        style={{ backgroundColor: color.background, borderColor: color.black }}
        header={renderHeader("Effects")}
        key="1"
      >
        <UICoreCol style={{ marginTop: 10, width: "100%" }}>
          <UICoreRow style={{ flex: 1 }}>
            <UICoreTypography
              style={styles.collapseItemLabel}
              title={"Opacity"}
            />
            <UICoreSlider style={{ flex: 1 }} />

            <UICoreInput
              placeholder={"0"}
              maxLength={5}
              style={styles.borderInput}
            />
          </UICoreRow>
          <UICoreCol style={styles.layoutLine} />

          {getAddItem("Box shadows")}
          {getAddItem("2D & 3D transforms")}
          {getAddItem("Transitions")}
          {getAddItem("Filter")}

          <UICoreRow style={{ marginTop: 10 }}>
            <UICoreTypography
              style={styles.layoutDisplayLabel}
              title={"Cursor"}
            />
            <UICoreRow style={{ flex: 1 }}>
              <UICoreSelect
                defaultValue={"auto"}
                dataList={getFittingListData()}
              />
            </UICoreRow>
          </UICoreRow>
        </UICoreCol>
      </Panel>
    </Collapse>
  );
}
