import React from "react";
import { Col } from "antd";
import { UICoreColProps } from "./components/ui-core-col.props";
import styles from "./components/ui-core-col.style";
import { log } from "../../utils/utils";

function UICol(props: UICoreColProps) {
  const { style, children, ref, ...rest } = props;
  return (
    <Col ref={ref} {...rest} style={{ ...styles.container, ...style }}>
      {children}
    </Col>
  );
}

export function UICoreCol(props: UICoreColProps) {
  return <UICol {...props} />;
}
