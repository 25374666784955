import React from "react";
import { Select } from "antd";
import { UICoreSelectProps } from "./components/ui-core-select.props";
import { color } from "../../theme/color";
import "./index.css";
import "antd/dist/antd.css";

const { Option } = Select;

function UICoreSelect(props: UICoreSelectProps) {
  const { style, dataList, ...rest } = props;
  return (
    <Select
      size={"small"}
      className="container-dropdown"
      style={{
        backgroundColor: color.background,
        background: color.background,
      }}
      dropdownStyle={{
        background: color.lightBlack,
        color: color.white,
      }}
      {...rest}
    >
      {dataList?.map((item, index) => {
        return (
          <Option style={{ color: color.white }} value={item.value}>
            {item.value}
          </Option>
        );
      })}
    </Select>
  );
}

export default UICoreSelect;
