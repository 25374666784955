import React from "react";
import { UICoreRowProps } from "./components/ui-core-row.props";
import styles from "./components/ui-core-row.style";
import { Row } from "antd";

function UIRow(props: UICoreRowProps) {
  const { style, children, ...rest } = props;
  return (
    <Row style={{ ...styles.container, ...style }} {...rest}>
      {children}
    </Row>
  );
}

export function UICoreRow(props: UICoreRowProps) {
  return <UIRow {...props} />;
}
