import { color } from "../../../theme/color";

interface StyleSheet {
  [key: string]: React.CSSProperties;
}

const LIST_ITEM = {
  marginLeft: 25,
  marginRight: 25,
  paddingLeft: 10,
  cursor: "pointer",
  marginTop: 10,
};

const styles: StyleSheet = {
  root: {
    flex: 0.3,
    backgroundColor: color.background,
  },
  addScreenView: {
    paddingLeft: 20,
    paddingTop: 15,
    paddingRight: 20,
  },
  addScreenLabel: {
    flex: 1,
    fontSize: 16,
    fontWeight: "bold",
    color: color.white,
  },
  addScreenIcon: {
    color: color.white,
    fontSize: "15px",
    marginTop: 5,
  },
  listItemLabel: {
    color: color.white,
    fontSize: "14px",
  },
  selectedItem: {
    ...LIST_ITEM,
    borderWidth: 1,
    borderColor: color.white,
    borderStyle: "solid",
    borderRadius: 2,
  },
  listItem: { ...LIST_ITEM },
};

export default styles;
