import React from "react";
import { Slider } from "antd";
import { UICoreSliderProps } from "./components/ui-core-slider.props";
import styles from "./components/ui-core-slider.style";
import { color } from "../../theme/color";

function UICoreSlider(props: UICoreSliderProps) {
  const { style, title, ...rest } = props;
  return (
    <Slider
      trackStyle={styles.trackStyle}
      handleStyle={styles.handleStyle}
      style={style}
      defaultValue={50}
      {...rest}
    />
  );
}

export default UICoreSlider;
