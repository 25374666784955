import { color } from "../theme/color";

export function log(value: any) {
  console.log(value);
}

export function getPropertiesData(): any {
  const propertiesData: any[] = [
    {
      layout: {
        label: "Layout",
        items: [{}],
      },
    },
  ];

  return propertiesData;
}

export function getFittingListData(): any {
  const fittingListData: any[] = [
    { value: "Fill" },
    { value: "Contain" },
    { value: "Cover" },
    { value: "None" },
    { value: "Scale Down" },
  ];

  return fittingListData;
}
