import { color } from "../../../theme/color";

interface StyleSheet {
  [key: string]: React.CSSProperties;
}
const styles: StyleSheet = {
  container: { backgroundColor: color.white, height: "100vh" },
};

export default styles;
