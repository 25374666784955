import React from "react";
import { UICoreRow } from "../../../uikit/ui-core-row/ui-core.row";
import {
  BorderInnerOutlined,
  CaretRightOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CloseOutlined,
  RotateLeftOutlined,
} from "@ant-design/icons";
import { UICoreCol } from "../../../uikit/ui-core-col/ui-core.col";
import { Collapse, Select } from "antd";
import { color } from "../../../theme/color";
import UICoreTypography from "../../../uikit/ui-core-typography/ui-core.typography";
import styles from "./right-panel.style";
import UICoreInput from "../../../uikit/ui-core-input/ui-core.input";
import "./index.css";
import UICoreSelect from "../../../uikit/ui-core-select/ui-core.select";
import { getFittingListData } from "../../../utils/utils";
import "./index.css";

const { Option } = Select;

const { Panel } = Collapse;

export default function TypographyView() {
  function renderHeader(label: any): any {
    return <UICoreTypography style={styles.collapseLabel} title={label} />;
  }
  function renderItem(label: any): any {
    return <UICoreTypography style={styles.collapseItemLabel} title={label} />;
  }
  return (
    <Collapse
      bordered={false}
      className={"header"}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ color: color.white }}
          rotate={isActive ? 90 : 0}
        />
      )}
    >
      <Panel
        style={{ backgroundColor: color.background, borderColor: color.black }}
        header={renderHeader("Typography")}
        key="1"
      >
        <UICoreCol style={{ width: "100%" }}>
          <UICoreRow style={{ marginTop: 10 }}>
            <UICoreTypography
              style={styles.layoutDisplayLabel}
              title={"Font"}
            />
            <UICoreRow style={{ flex: 1 }}>
              <UICoreSelect
                defaultValue={"Inconsolata"}
                dataList={getFittingListData()}
              />
            </UICoreRow>
          </UICoreRow>

          <UICoreRow style={{ marginTop: 10 }}>
            <UICoreTypography
              style={styles.layoutDisplayLabel}
              title={"Weight"}
            />
            <UICoreRow style={{ flex: 1 }}>
              <UICoreSelect
                defaultValue={"400 - Normal"}
                dataList={getFittingListData()}
              />
            </UICoreRow>
          </UICoreRow>

          <UICoreRow style={{ marginTop: 10 }}>
            <UICoreRow style={{ flex: 1 }}>
              <UICoreTypography style={styles.sizeInputLabel} title={"Size"} />
              <UICoreInput
                placeholder={"0"}
                maxLength={5}
                style={styles.sizeInput}
              />
            </UICoreRow>

            <UICoreRow style={{ flex: 1, marginLeft: 10 }}>
              <UICoreTypography
                style={styles.sizeInputLabel}
                title={"Height"}
              />
              <UICoreInput
                placeholder={"0"}
                maxLength={5}
                style={styles.sizeInput}
              />
            </UICoreRow>
          </UICoreRow>

          <UICoreRow style={{ marginTop: 10 }}>
            <UICoreRow style={{ flex: 1 }}>
              <UICoreTypography
                style={styles.layoutDisplayLabel}
                title={"Color"}
              />
              <UICoreInput
                placeholder={"#333"}
                maxLength={5}
                style={styles.sizeInputColor}
              />
            </UICoreRow>
          </UICoreRow>

          <UICoreRow style={{ marginTop: 10 }}>
            <UICoreTypography
              style={styles.layoutDisplayLabel}
              title={"Align"}
            />
            <UICoreRow style={{ flex: 1 }}>
              <UICoreRow style={styles.layoutIconView}>
                <AlignCenterOutlined style={styles.layoutIcon} />
                <AlignRightOutlined style={styles.layoutIcon} />
                <AlignCenterOutlined style={styles.layoutIcon} />
                <AlignRightOutlined style={styles.layoutIcon} />
                <AlignCenterOutlined style={styles.layoutIcon} />
                <AlignRightOutlined style={styles.layoutLastIcon} />
              </UICoreRow>
            </UICoreRow>
          </UICoreRow>

          <UICoreRow style={{ marginTop: 10 }}>
            <UICoreTypography
              style={styles.layoutDisplayLabel}
              title={"Style"}
            />
            <UICoreRow style={{ flex: 1 }}>
              <UICoreRow style={styles.layoutIconView}>
                <AlignCenterOutlined style={styles.layoutIcon} />
                <AlignRightOutlined style={styles.layoutIcon} />
                <AlignCenterOutlined style={styles.layoutIcon} />
                <AlignRightOutlined style={styles.layoutIcon} />
                <AlignCenterOutlined style={styles.layoutIcon} />
                <AlignRightOutlined style={styles.layoutLastIcon} />
              </UICoreRow>
            </UICoreRow>
          </UICoreRow>


          <UICoreRow style={{ marginTop: 10 }}>
            <UICoreRow style={{ flex: 1 }}>
              <UICoreTypography
                style={styles.layoutDisplayLabel}
                title={"Color"}
              />
              <UICoreInput
                placeholder={"#333"}
                maxLength={5}
                style={styles.sizeInputColor}
              />
            </UICoreRow>
          </UICoreRow>

          <Collapse
            accordion
            style={{ marginTop: 20, borderRadius: 5 }}
            bordered={false}
            className={"collapse_item"}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined
                style={{ color: color.white }}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            <Panel
              className={"collapse_item"}
              style={{ backgroundColor: color.background, border: 0 }}
              header={renderItem("More type options")}
              key="1"
            >
              <UICoreRow style={{ marginTop: 10, flex: 1 }}>
                <UICoreInput
                  placeholder={"Normal"}
                  maxLength={5}
                  style={styles.sizeInput}
                />

                <UICoreInput
                  placeholder={"0"}
                  maxLength={5}
                  style={styles.sizeInput}
                />

                <UICoreInput
                  placeholder={"Auto"}
                  maxLength={5}
                  style={styles.sizeInput}
                />
              </UICoreRow>

              <UICoreRow style={{ flex: 1, marginTop: 10 }}>
                <UICoreRow style={styles.layoutIconView}>
                  <AlignCenterOutlined style={styles.layoutIcon} />
                  <AlignRightOutlined style={styles.layoutIcon} />
                  <AlignCenterOutlined style={styles.layoutIcon} />
                  <AlignRightOutlined style={styles.layoutIcon} />
                  <AlignCenterOutlined style={styles.layoutIcon} />
                  <AlignRightOutlined style={styles.layoutLastIcon} />
                </UICoreRow>
              </UICoreRow>
            </Panel>
          </Collapse>
        </UICoreCol>
      </Panel>
    </Collapse>
  );
}
