import React from "react";
import { UICoreRow } from "../../../uikit/ui-core-row/ui-core.row";
import {
  CaretRightOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
  CloseOutlined,
  RotateLeftOutlined,
} from "@ant-design/icons";
import { UICoreCol } from "../../../uikit/ui-core-col/ui-core.col";
import { Collapse, Select } from "antd";
import { color } from "../../../theme/color";
import UICoreTypography from "../../../uikit/ui-core-typography/ui-core.typography";
import styles from "./right-panel.style";
import "./index.css";
import UICoreSelect from "../../../uikit/ui-core-select/ui-core.select";
import { getFittingListData } from "../../../utils/utils";

const { Panel } = Collapse;

export default function PositionView() {
  function renderHeader(label: any): any {
    return <UICoreTypography style={styles.collapseLabel} title={label} />;
  }

  function renderItem(label: any): any {
    return <UICoreTypography style={styles.collapseItemLabel} title={label} />;
  }
  return (
    <Collapse
      bordered={false}
      className={"header"}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ color: color.white }}
          rotate={isActive ? 90 : 0}
        />
      )}
    >
      <Panel
        style={{
          backgroundColor: color.background,
          borderColor: color.black,
        }}
        header={renderHeader("Position")}
        key="1"
      >
        <UICoreCol style={{ width: "100%" }}>
          <UICoreRow style={{ marginTop: 10 }}>
            <UICoreTypography
              style={styles.layoutDisplayLabel}
              title={"Position"}
            />
            <UICoreRow style={{ flex: 1 }}>
              <UICoreSelect
                defaultValue={"Static"}
                dataList={getFittingListData()}
              />
            </UICoreRow>
          </UICoreRow>

          <Collapse
            accordion
            style={{ marginTop: 20, borderRadius: 5 }}
            bordered={false}
            className={"collapse_item"}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined
                style={{ color: color.white }}
                rotate={isActive ? 90 : 0}
              />
            )}
          >
            <Panel
              className={"collapse_item"}
              style={{ backgroundColor: color.background, border: 0 }}
              header={renderItem("Float and clear")}
              key="1"
            >
              <UICoreRow style={{ marginTop: 10 }}>
                <UICoreTypography
                  style={styles.layoutDisplayLabel}
                  title={"Float"}
                />
                <UICoreRow style={{ flex: 1, width: "100%" }}>
                  <UICoreRow style={styles.layoutIconView}>
                    <CloseOutlined style={styles.layoutIcon} />
                    <RotateLeftOutlined style={styles.layoutIcon} />
                    <RotateLeftOutlined style={styles.layoutLastIcon} />
                  </UICoreRow>
                </UICoreRow>
              </UICoreRow>

              <UICoreRow style={{ marginTop: 10 }}>
                <UICoreTypography
                  style={styles.layoutDisplayLabel}
                  title={"Clear"}
                />
                <UICoreRow style={{ flex: 1 }}>
                  <UICoreRow style={styles.layoutIconView}>
                    <CloseOutlined style={styles.layoutIcon} />
                    <RotateLeftOutlined style={styles.layoutIcon} />
                    <RotateLeftOutlined style={styles.layoutIcon} />
                    <RotateLeftOutlined style={styles.layoutLastIcon} />
                  </UICoreRow>
                </UICoreRow>
              </UICoreRow>
            </Panel>
          </Collapse>
        </UICoreCol>
      </Panel>
    </Collapse>
  );
}
