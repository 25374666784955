export const palette = {
  background: "#404040",
  white: "#ffffff",
  lightWhite: "#D1D1D1",
  mousePointer: "#61696b",
  black: "#242424",
  lightBlack: "#323232",
  disable: "#5e5e5e",
  itemBackground: "#4d4d4d",
  transparent: "rgba(0, 0, 0, 0)",
};
