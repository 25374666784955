import React from "react";
import { RightPanelProps } from "./right-panel.props";
import { UICoreScreen } from "../../ui-core-screen/ui-core.screen";
import styles from "./right-panel.style";
import LayoutView from "./layout.view";
import SizeView from "./size.view";
import PositionView from "./position.view";
import Typography from "./typography.view";
import BackgroundView from "./background.view";
import BordersView from "./borders.view";
import EffectsView from "./effects.view";
import SvgFile from "./../../../assets/svgs/svgs.svg";
import UICoreSVG from "../../../uikit/ui-core-svg/ui-core.svg";

function RightPanelView(props: RightPanelProps) {
  return (
    <div style={{ width: "20%", overflowY: "scroll" }}>
      <UICoreScreen style={styles.root}>
        <LayoutView />
        <SizeView />
        <PositionView />
        <Typography />
        <BackgroundView />
        <BordersView />
        <EffectsView />
      </UICoreScreen>
    </div>
  );
}

export default RightPanelView;
