import React from "react";
import { Input } from "antd";
import { UICoreInputProps } from "./components/ui-core-input.props";

function UICoreInput(props: UICoreInputProps) {
  const { style, value, ...rest } = props;
  return <Input {...rest} style={style} value={value} />;
}

export default UICoreInput;
