import React from "react";
import { Button } from "antd";
import { UICoreButtonProps } from "./components/ui-core-button.props";

function UICoreButton(props: UICoreButtonProps) {
  const { style, title, type = "primary" } = props;
  return (
    <Button type={type} style={style}>
      {title}
    </Button>
  );
}

export default UICoreButton;
