interface StyleSheet {
  [key: string]: React.CSSProperties;
}
const styles: StyleSheet = {
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
};

export default styles;
