export enum COMPONENT {
  COMPONENT_ROW = "ROW",
  COMPONENT_ROW_CONTENT = "ROW_CONTENT",
  COMPONENT_GRID = "GRID",
  COMPONENT_COLUMN = "COLUMN",
  COMPONENT_BUTTON = "BUTTON",
  COMPONENT_HEADER = "HEADER",
  COMPONENT_PARAGRAPH = "PARAGRAPH",
  COMPONENT_PLACEHOLDER = "PLACEHOLDER",
  COMPONENT_TEXT = "TEXT",
  COMPONENT_TEXT_INPUT = "TEXT_INPUT",
}
