import React from "react";
import { UICoreRow } from "../../../uikit/ui-core-row/ui-core.row";
import {
  BorderInnerOutlined,
  CaretRightOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import { UICoreCol } from "../../../uikit/ui-core-col/ui-core.col";
import { Collapse } from "antd";
import { color } from "../../../theme/color";
import UICoreTypography from "../../../uikit/ui-core-typography/ui-core.typography";
import styles from "./right-panel.style";
import "./index.css";
const { Panel } = Collapse;

export default function LayoutView() {
  function renderHeader(label: any): any {
    return <UICoreTypography style={styles.collapseLabel} title={label} />;
  }
  return (
    <Collapse
      className={"header"}
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ color: color.white }}
          rotate={isActive ? 90 : 0}
        />
      )}
    >
      <Panel
        style={{ backgroundColor: color.background, borderColor: color.black }}
        header={renderHeader("Layout")}
        key="1"
      >
        <UICoreCol style={{ width: "100%" }}>
          <UICoreRow style={{ flex: 1 }}>
            <UICoreTypography
              style={styles.layoutDisplayLabel}
              title={"Display"}
            />
            <UICoreRow style={{ flex: 1 }}>
              <UICoreRow style={styles.layoutIconView}>
                <BorderInnerOutlined style={styles.layoutIcon} />
                <BorderInnerOutlined style={styles.layoutIcon} />
                <BorderInnerOutlined style={styles.layoutIcon} />
                <BorderInnerOutlined style={styles.layoutIcon} />
                <BorderInnerOutlined style={styles.layoutIcon} />
                <BorderInnerOutlined style={styles.layoutLastIcon} />
              </UICoreRow>
            </UICoreRow>
          </UICoreRow>

          <UICoreCol style={styles.layoutLine} />

          <UICoreRow>
            <UICoreTypography
              style={styles.layoutDisplayLabel}
              title={"Direction"}
            />
            <UICoreRow style={{ flex: 1 }}>
              <UICoreRow style={styles.layoutIconView}>
                <UICoreCol style={styles.selectedDirectionView}>
                  <UICoreTypography
                    style={styles.selectedDirectionLabel}
                    title={"Horizontal"}
                  />
                </UICoreCol>
                <UICoreCol style={styles.unselectedDirectionView}>
                  <UICoreTypography
                    style={styles.unselectedDirectionLabel}
                    title={"Vertical"}
                  />
                </UICoreCol>
              </UICoreRow>
            </UICoreRow>
          </UICoreRow>

          <UICoreCol style={styles.layoutLine} />

          <UICoreRow>
            <UICoreTypography
              style={styles.layoutDisplayLabel}
              title={"Align"}
            />
            <UICoreRow style={{ flex: 1 }}>
              <UICoreRow style={styles.layoutIconView}>
                <AlignCenterOutlined style={styles.layoutIcon} />
                <AlignRightOutlined style={styles.layoutIcon} />
                <AlignCenterOutlined style={styles.layoutIcon} />
                <AlignRightOutlined style={styles.layoutIcon} />
                <AlignCenterOutlined style={styles.layoutIcon} />
                <AlignRightOutlined style={styles.layoutLastIcon} />
              </UICoreRow>
            </UICoreRow>
          </UICoreRow>

          <UICoreCol style={styles.layoutLine} />

          <UICoreRow>
            <UICoreTypography
              style={styles.layoutDisplayLabel}
              title={"Justify"}
            />
            <UICoreRow style={{ flex: 1 }}>
              <UICoreRow style={styles.layoutIconView}>
                <AlignCenterOutlined style={styles.layoutIcon} />
                <AlignRightOutlined style={styles.layoutIcon} />
                <AlignCenterOutlined style={styles.layoutIcon} />
                <AlignRightOutlined style={styles.layoutIcon} />
                <AlignCenterOutlined style={styles.layoutIcon} />
                <AlignRightOutlined style={styles.layoutLastIcon} />
              </UICoreRow>
            </UICoreRow>
          </UICoreRow>

          <UICoreCol style={styles.layoutLine} />

          <UICoreRow>
            <UICoreTypography
              style={styles.layoutDisplayLabel}
              title={"Children"}
            />
            <UICoreRow style={{ flex: 1 }}>
              <UICoreRow style={styles.layoutIconView}>
                <UICoreCol style={styles.selectedDirectionView}>
                  <UICoreTypography
                    style={styles.selectedDirectionLabel}
                    title={"Don't wrap"}
                  />
                </UICoreCol>
                <UICoreCol style={styles.unselectedDirectionView}>
                  <UICoreTypography
                    style={styles.unselectedDirectionLabel}
                    title={"Wrap"}
                  />
                </UICoreCol>
              </UICoreRow>
            </UICoreRow>
          </UICoreRow>
        </UICoreCol>
      </Panel>
    </Collapse>
  );
}
