import React from "react";
import { Layout } from "antd";
import { UICoreLayoutProps } from "./components/ui-core-layout.props";
import styles from "./components/ui-core-layout.style";

function UILayout(props: UICoreLayoutProps) {
  const { style, children } = props;
  return <Layout style={{ ...styles.container, ...style }}>{children}</Layout>;
}

export function UICoreLayout(props: UICoreLayoutProps) {
  return <UILayout {...props} />;
}
