import React from "react";
import { UICoreRow } from "../../../uikit/ui-core-row/ui-core.row";
import {
  CaretRightOutlined,
  CheckSquareOutlined,
  BorderOutlined,
  BorderOuterOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import { UICoreCol } from "../../../uikit/ui-core-col/ui-core.col";
import { Collapse, Select } from "antd";
import { color } from "../../../theme/color";
import UICoreTypography from "../../../uikit/ui-core-typography/ui-core.typography";
import styles from "./right-panel.style";
import UICoreInput from "../../../uikit/ui-core-input/ui-core.input";
import "./index.css";
import "./index.css";
import UICoreSlider from "../../../uikit/ui-core-slider/ui-core-slider";

const { Panel } = Collapse;

export default function BordersView() {
  function renderHeader(label: any): any {
    return <UICoreTypography style={styles.collapseLabel} title={label} />;
  }

  function getBoxIcon() {
    return (
      <BorderOutlined
        style={{
          color: color.white,
          marginRight: 10,
        }}
      />
    );
  }

  return (
    <Collapse
      bordered={false}
      className={"header"}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ color: color.white }}
          rotate={isActive ? 90 : 0}
        />
      )}
    >
      <Panel
        style={{ backgroundColor: color.background, borderColor: color.black }}
        header={renderHeader("Borders")}
        key="1"
      >
        <UICoreCol style={{ marginTop: 10, width: "100%" }}>
          <UICoreRow style={{ flex: 1 }}>
            <UICoreRow>
              <UICoreTypography
                style={styles.boarderLabelFlex1}
                title={"Radius"}
              />
              <BorderOuterOutlined
                style={{ color: color.white, marginRight: 8 }}
              />
            </UICoreRow>
            <BorderOutlined style={{ color: color.white }} />
            <UICoreSlider style={{ width: 70, marginLeft: 10 }} />

            <UICoreInput
              placeholder={"0"}
              maxLength={5}
              style={styles.borderInput}
            />
          </UICoreRow>

          <UICoreRow style={{ flex: 1, marginTop: 10 }}>
            <UICoreRow style={{ flex: 1.5 }} />
            <BorderOuterOutlined
              style={{ color: color.white, marginRight: 5 }}
            />
            <UICoreInput
              placeholder={"0"}
              maxLength={5}
              style={styles.borderInput}
            />

            <BorderOuterOutlined
              style={{ color: color.white, marginLeft: 15 }}
            />
            <UICoreInput
              placeholder={"0"}
              maxLength={5}
              style={styles.borderInput}
            />
          </UICoreRow>

          <UICoreRow style={{ flex: 1, marginTop: 10 }}>
            <UICoreRow style={{ flex: 1.5 }} />
            <BorderOuterOutlined
              style={{ color: color.white, marginRight: 5 }}
            />
            <UICoreInput
              placeholder={"0"}
              maxLength={5}
              style={styles.borderInput}
            />

            <BorderOuterOutlined
              style={{ color: color.white, marginLeft: 15 }}
            />
            <UICoreInput
              placeholder={"0"}
              maxLength={5}
              style={styles.borderInput}
            />
          </UICoreRow>

          <UICoreCol
            style={Object.assign(styles.layoutLine, { marginTop: 15 })}
          />
          <UICoreTypography
            style={styles.boarderLabelFlex1}
            title={"Borders"}
          />
          <UICoreRow>
            <UICoreCol style={{ flex: 0.7 }}>
              {getBoxIcon()}

              <UICoreRow style={{ marginTop: 10, marginBottom: 10 }}>
                {getBoxIcon()}
                {getBoxIcon()}
                {getBoxIcon()}
              </UICoreRow>

              {getBoxIcon()}
            </UICoreCol>

            <UICoreCol style={{ flex: 1 }}>
              <UICoreRow style={{ marginTop: 10 }}>
                <UICoreTypography
                  style={styles.layoutDisplayLabel}
                  title={"Style"}
                />
                <UICoreRow style={{ flex: 1 }}>
                  <UICoreRow style={styles.layoutIconView}>
                    <AlignCenterOutlined style={styles.layoutIcon} />
                    <AlignRightOutlined style={styles.layoutIcon} />
                    <AlignRightOutlined style={styles.layoutIcon} />
                  </UICoreRow>
                </UICoreRow>
              </UICoreRow>

              <UICoreRow style={{ marginTop: 10 }}>
                <UICoreRow style={{ flex: 1 }}>
                  <UICoreTypography
                    style={styles.layoutDisplayLabel}
                    title={"Width"}
                  />
                  <UICoreInput
                    placeholder={"0"}
                    maxLength={5}
                    style={styles.sizeInputColor}
                  />
                </UICoreRow>
              </UICoreRow>

              <UICoreRow style={{ marginTop: 10 }}>
                <UICoreRow style={{ flex: 1 }}>
                  <UICoreTypography
                    style={styles.layoutDisplayLabel}
                    title={"Color"}
                  />
                  <UICoreInput
                    placeholder={"Black"}
                    maxLength={5}
                    style={styles.sizeInputColor}
                  />
                </UICoreRow>
              </UICoreRow>
            </UICoreCol>
          </UICoreRow>
        </UICoreCol>
      </Panel>
    </Collapse>
  );
}
