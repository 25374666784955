import { color } from "../../../theme/color";

interface StyleSheet {
  [key: string]: React.CSSProperties;
}
const styles: StyleSheet = {
  trackStyle: { backgroundColor: color.lightWhite, height: 2 },
  handleStyle: {
    borderColor: color.lightWhite,
    backgroundColor: color.lightWhite,
  },
};

export default styles;
