import React, { useCallback } from "react";
import { MiddlePanelProps } from "./middle-panel.props";
import { UICoreLayout } from "../../../uikit/ui-core-layout/ui-core.layout";
import { UICoreRow } from "../../../uikit/ui-core-row/ui-core.row";
import { UICoreCol } from "../../../uikit/ui-core-col/ui-core.col";
import { color } from "../../../theme/color";
import styles from "./middle-panel.style";
import UICoreTypography from "../../../uikit/ui-core-typography/ui-core.typography";
import { useMiddlePanel } from "./middle-panel.hooks";
import { log } from "../../../utils/utils";
import BottomItemView from "./bottom-item.view";
import UICoreButton from "../../../uikit/ui-core-button/ui-core.button";
import { COMPONENT } from "../component-type";
import { AnyPtrRecord } from "dns";

function MiddlePanelView(props: MiddlePanelProps) {
  const {
    viewList,
    setView,
    rowContents,
    setRowContent,
    selectedIndex,
    setSelectedIndex,
  } = useMiddlePanel();

  function getContentView(id: string, toolType: any, margin: number): any {
    let style = Object.assign({ margin: margin * 10 }, styles.droppedRowView);
    return {
      id: id,
      style: styles.droppedRowView,
      type: toolType,
      selected: true,
      content: [],
    };
  }

  function getButton(id: string, toolType: any): any {
    return {
      id: id,
      style: styles.droppedRowView,
      type: toolType,
      selected: true,
      content: [],
    };
  }

  function getText(id: string, toolType: any): any {
    return {
      id: id,
      style: styles.droppedRowView,
      type: toolType,
      selected: true,
      content: [],
    };
  }

  function getHeader(id: string, toolType: any): any {
    return {
      id: id,
      style: styles.droppedRowView,
      type: toolType,
      selected: true,
      content: [],
    };
  }

  function getParagraph(id: string, toolType: AnyPtrRecord): any {
    return {
      id: id,
      style: styles.droppedRowView,
      type: toolType,
      selected: true,
      content: [],
    };
  }

  function updateViews(id: any, type: any) {
    let arrayList = viewList.slice();
    arrayList.forEach((item, index) => {
      if (item.id === id) {
        if (type === COMPONENT.COMPONENT_ROW) {
          arrayList[index].content.push(
            getContentView(
              COMPONENT.COMPONENT_ROW + "_" + index,
              type,
              index + 1
            )
          );
        } else if (type === COMPONENT.COMPONENT_BUTTON) {
          arrayList[index].content.push(
            getButton(COMPONENT.COMPONENT_BUTTON + "_" + index, type)
          );
        } else if (type === COMPONENT.COMPONENT_HEADER) {
          arrayList[index].content.push(
            getHeader(COMPONENT.COMPONENT_HEADER + "_" + index, type)
          );
        } else if (type === COMPONENT.COMPONENT_PARAGRAPH) {
          arrayList[index].content.push(
            getParagraph(COMPONENT.COMPONENT_PARAGRAPH + "_" + index, type)
          );
        } else if (type === COMPONENT.COMPONENT_PLACEHOLDER) {
          arrayList[index].content.push(
            getParagraph(COMPONENT.COMPONENT_PLACEHOLDER + "_" + index, type)
          );
        }
      }
    });
    setView(arrayList);
  }

  const onDrop = (e: any) => {
    const target = e.target;
    e.preventDefault();
    const toolType = e.dataTransfer.getData("COMPONENT");
    let itemList = viewList.slice();
    let item: any = {};
    if (toolType === COMPONENT.COMPONENT_ROW) {
      let content;
      if (target && target.id) {
        updateViews(target.id, COMPONENT.COMPONENT_ROW);
      } else {
        content = getContentView(COMPONENT.COMPONENT_ROW, toolType, 0);
      }
      if (content) {
        itemList.push(content);
      }
    } else if (toolType === COMPONENT.COMPONENT_COLUMN) {
      let content;
      if (target && target.id) {
        updateViews(target.id, COMPONENT.COMPONENT_COLUMN);
      } else {
        content = getContentView(COMPONENT.COMPONENT_COLUMN, toolType, 0);
      }
      if (content) {
        itemList.push(content);
      }
    } else if (toolType === COMPONENT.COMPONENT_GRID) {
      let content;
      if (target && target.id) {
        updateViews(target.id, COMPONENT.COMPONENT_GRID);
      } else {
        content = getContentView(COMPONENT.COMPONENT_GRID, toolType, 0);
      }
      if (content) {
        itemList.push(content);
      }
    } else if (toolType === COMPONENT.COMPONENT_BUTTON) {
      let content;
      if (target && target.id) {
        updateViews(target.id, COMPONENT.COMPONENT_BUTTON);
      } else {
        content = getButton(COMPONENT.COMPONENT_BUTTON, toolType);
      }
      if (content) {
        itemList.push(content);
      }
    } else if (toolType === COMPONENT.COMPONENT_HEADER) {
      let content;
      if (target && target.id) {
        updateViews(target.id, COMPONENT.COMPONENT_HEADER);
      } else {
        content = getHeader(COMPONENT.COMPONENT_HEADER, toolType);
      }
      if (content) {
        itemList.push(content);
      }
    } else if (toolType === COMPONENT.COMPONENT_PARAGRAPH) {
      let content;
      if (target && target.id) {
        updateViews(target.id, COMPONENT.COMPONENT_PARAGRAPH);
      } else {
        content = getParagraph(COMPONENT.COMPONENT_PARAGRAPH, toolType);
      }
      if (content) {
        itemList.push(content);
      }
    }
    setSelectedIndex(itemList.length - 1);
    setView(itemList);
  };

  function onDragOver(e: any) {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  }

  function onRowDragStart(e: any) {
    e.dataTransfer.setData("COMPONENT", COMPONENT.COMPONENT_ROW);
  }

  function onButtonDragStart(e: any) {
    e.dataTransfer.setData("COMPONENT", COMPONENT.COMPONENT_BUTTON);
  }

  function onHeaderDragStart(e: any) {
    e.dataTransfer.setData("COMPONENT", COMPONENT.COMPONENT_HEADER);
  }

  function onParagraphDragStart(e: any) {
    e.dataTransfer.setData("COMPONENT", COMPONENT.COMPONENT_PARAGRAPH);
  }

  function onPlaceholderDragStart(e: any) {
    e.dataTransfer.setData("COMPONENT", COMPONENT.COMPONENT_PLACEHOLDER);
  }

  function onColDragStart(e: any) {
    e.dataTransfer.setData("COMPONENT", COMPONENT.COMPONENT_COLUMN);
  }

  function onGridDragStart(e: any) {
    e.dataTransfer.setData("COMPONENT", COMPONENT.COMPONENT_GRID);
  }

  function renderViewList(): any[] {
    const list: any[] = [];
    viewList.map((item, index) => {
      list.push(
        <UICoreCol
          key={index}
          style={styles.dropView}
          onClick={() => {
            setSelectedIndex(index);
          }}
        >
          <UICoreCol
            key={index}
            style={
              index === selectedIndex
                ? styles.selectedDroppedRowView
                : styles.droppedRowView
            }
          >
            {createElement(item)}
          </UICoreCol>
        </UICoreCol>
      );
    });
    return list;
  }

  function createElement(data: any) {
    const { type } = data;
    if (type === COMPONENT.COMPONENT_ROW) {
      return createRow(data);
    } else if (type === COMPONENT.COMPONENT_COLUMN) {
      return createColumn(data);
    } else if (type === COMPONENT.COMPONENT_GRID) {
      return createGrid(data);
    } else if (type === COMPONENT.COMPONENT_BUTTON) {
      return createButton(data);
    } else if (type === COMPONENT.COMPONENT_HEADER) {
      return createHeader(data);
    } else if (type === COMPONENT.COMPONENT_PARAGRAPH) {
      return createParagraph(data);
    } else if (type === COMPONENT.COMPONENT_PLACEHOLDER) {
      return createParagraph(data);
    }
  }

  function createButton(data: any) {
    const { id, style, type, content } = data;
    return (
      <div id={id} style={style}>
        <UICoreButton title={"Button"} />
      </div>
    );
  }

  function createHeader(data: any) {
    const { id, style, type, content } = data;
    return (
      <div id={id} style={style}>
        <UICoreTypography
          style={{ fontSize: 24, fontWeight: "bold", color: color.black }}
          title={"Header"}
        />
      </div>
    );
  }

  function createParagraph(data: any) {
    const { id, style, type, content } = data;
    return (
      <div id={id} style={style}>
        <UICoreTypography
          title={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere."
          }
        />
      </div>
    );
  }

  function createRow(data: any): any {
    const { id, style, type, content } = data;
    log(content);
    let element = (
      <div id={id} style={style}>
        {content.map((item: any, index: any) => {
          if (item.type === COMPONENT.COMPONENT_BUTTON) {
            return createButton(item);
          } else if (item.type === COMPONENT.COMPONENT_HEADER) {
            return createHeader(item);
          } else if (item.type === COMPONENT.COMPONENT_PARAGRAPH) {
            return createParagraph(item);
          } else if (item.type === COMPONENT.COMPONENT_PLACEHOLDER) {
            return createParagraph(item);
          }
        })}
      </div>
    );
    return element;
  }

  function createGrid(data: any): any {
    const { id, style, type, content } = data;
    log(content);
    let element = (
      <div id={id} style={style}>
        {content.map((item: any, index: any) => {
          return <div id={item.id + "_" + index} style={item.style} />;
        })}
      </div>
    );
    return element;
  }

  function createColumn(data: any): any {
    const { id, style, type, content } = data;
    log(content);
    let element = (
      <div id={id} style={style}>
        {content.map((item: any, index: any) => {
          return <div id={item.id + "_" + index} style={item.style} />;
        })}
      </div>
    );
    return element;
  }

  return (
    <UICoreLayout style={{ flex: 1 }}>
      <div onDrop={onDrop} onDragOver={onDragOver} style={styles.rootView}>
        {renderViewList()}
      </div>

      <UICoreRow
        style={{
          backgroundColor: color.white,
        }}
      >
        <BottomItemView
          onDragStart={onRowDragStart}
          onDragOver={onDragOver}
          bottomItemType={"row"}
        />

        <BottomItemView
          onDragStart={onColDragStart}
          onDragOver={onDragOver}
          bottomItemType={"column"}
        />

        <BottomItemView
          onDragStart={onGridDragStart}
          onDragOver={onDragOver}
          bottomItemType={"grid"}
        />
      </UICoreRow>

      <UICoreRow
        style={{
          backgroundColor: color.background,
          flexDirection: "row",
          paddingBottom: 15,
        }}
      >
        <div
          draggable
          style={styles.componentBorder}
          onDragStart={onButtonDragStart}
          // onDragOver={onDragOver}
        >
          <UICoreTypography style={styles.componentText} title={"Button"} />
        </div>

        <div
          draggable
          style={styles.componentBorder}
          onDragStart={onHeaderDragStart}
          onDragOver={onDragOver}
        >
          <UICoreTypography style={styles.componentText} title={"Header"} />
        </div>

        <div
          style={styles.componentBorder}
          draggable
          onDragStart={onParagraphDragStart}
          onDragOver={onDragOver}
        >
          <UICoreTypography style={styles.componentText} title={"Paragraph"} />
        </div>

        <div
          style={styles.componentBorder}
          draggable
          onDragStart={onPlaceholderDragStart}
          onDragOver={onDragOver}
        >
          <UICoreTypography
            style={styles.componentText}
            title={"Placeholder"}
          />
        </div>
      </UICoreRow>
    </UICoreLayout>
  );
}

export default MiddlePanelView;
