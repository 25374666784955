import React from "react";
import LeftPanelContainer from "../../components/left-panel/left-panel.container";
import RightPanelContainer from "../../components/right-panel/right-panel.container";
import MiddlePanelContainer from "../../components/middle-panel/middle-panel.container";
import { UICoreScreen } from "../../components/ui-core-screen/ui-core.screen";

function HomeScreen() {
  return (
    <UICoreScreen style={{ flexDirection: "row", overflow: "unset" }}>
      <LeftPanelContainer />

      <MiddlePanelContainer />

      <RightPanelContainer />
    </UICoreScreen>
  );
}

export default HomeScreen;
